<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    class="gradient relative hidden overflow-hidden px-7 py-10 text-white sm:block xl:pr-24"
  >
    <div class="relative z-20 flex justify-between">
      <div class="flex flex-col justify-between">
        <p
          v-if="props.title"
          class="mb-6 text-xl font-bold leading-6"
        >
          {{ props.title }}
        </p>
        <p
          v-if="props.description"
          class="mb-6 w-60 text-base font-normal"
        >
          {{ props.description }}
        </p>

        <div
          v-for="box in props.boxes"
          :key="`${box.id}-mobile`"
          class="mb-2.5 flex items-center last:mb-0 xl:hidden"
        >
          <UiIconBoxItem
            :title="box.content?.header"
            :description="box.content?.description"
            :icon="box.content?.icon"
          />
        </div>

        <UiButton
          v-if="props.buttonLink"
          :to="props.buttonLink"
          icon-right="arrow"
          class="mt-10 w-full"
        >
          {{ props.buttonText }}
        </UiButton>
      </div>
      <div class="hidden xl:block">
        <div
          v-for="box in props.boxes"
          :key="`${box.id}-desktop`"
          class="mb-2.5 flex items-center last:mb-0"
        >
          <UiIconBoxItem
            :title="box.content?.header"
            :description="box.content?.description"
            :icon="box.content?.icon"
          />
        </div>
      </div>
    </div>
    <UiIcon
      name="logo-icon-box-horizontal"
      :width="884"
      :height="308"
      class="absolute left-0 top-0 hidden w-full xl:block"
    />
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../../UiIcon/UiIcon.vue'
import UiIconBoxItem from '../UiIconBoxItem/UiIconBoxItem.vue'
import UiButton from '../../UiButton/UiButton.vue'
import type { IconBox } from '../UiIconBox.types'

interface UiIconBox {
  boxes: IconBox[]
  title: string
  description: string
  buttonLink?: string
  buttonText?: string
}

const props = withDefaults(defineProps<UiIconBox>(), {
  buttonLink: '',
  buttonText: '',
})
</script>

<style lang="postcss" scoped>
.gradient {
  background: linear-gradient(90deg, #003366 0.73%, #1d62a7 100%);
}
</style>
